import React, { useState } from 'react';
import './styles602.css';
import HIMYM from './HIMYM.jpg';
import Haikyu from './haikyu.jpeg';
import naruto from './naruto.jpeg';
import volleyball from './VB.png';
import pingpong from './pingpong.jpg';
import GP from './GP.png';
import lol from './Films & TV 2022-12-20 21-43-20.mp4';
import { MdCancel } from "react-icons/md";


const BoxContainer = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState({ src: '', type: '' });
  
    const boxData = [
      { src: HIMYM, src2: 'https://www.youtube.com/embed/TjapUF_5Brg?si=PAS5xAyFjr7IoF49', label: 'HIMYM', type: 'youtube' },
      { src: Haikyu, src2: 'https://www.youtube.com/embed/-hGs4HcpuwE?si=SntJLVdzGb0Skulz', label: 'Haikyu', type: 'youtube' },
      { src: naruto, src2: 'https://www.youtube.com/embed/0bjicmknkVw?si=EU49NG5uIdtmaFmP', label: 'Naruto', type: 'youtube' },
      { src: volleyball, src2: 'https://source.unsplash.com/1000x800', label: 'Volleyball', type: 'image' },
      { src: pingpong, src2: 'https://source.unsplash.com/1000x800', label: 'Ping Pong', type: 'image' },
      { src: GP, src2: lol, label: 'League of Legends', type: 'video' },
    ];
  
    const handleBoxClick = (index) => {
      setShowPopup(true);
      setPopupContent(boxData[index]);
    };
  
    const handleClosePopup = () => {
      setShowPopup(false);
      setPopupContent({ src: '', type: '' });
    };
  
    return (
      <div className="container-602">
        {boxData.map((box, index) => (
          <div
            key={index}
            className={`box-602`}
            onClick={() => handleBoxClick(index)}
          >
            <div
              className="box-overlay-602"
              style={{ backgroundImage: `url(${box.src})` }}
            />
            <div className="box-content-602">
              <span>{box.label}</span>
            </div>
          </div>
        ))}
        {showPopup && (
          <div className="popup">
            <span className="close-button" onClick={handleClosePopup}><MdCancel /></span>
            {popupContent.type === 'video' ? (
              <video src={popupContent.src2} autoPlay controls />
            ) : popupContent.type === 'youtube' ? (
              <iframe
                src={popupContent.src2}
                title={popupContent.label}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <img src={popupContent.src2} alt={popupContent.label} />
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default BoxContainer;
  