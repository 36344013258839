import React, { useState } from 'react';
import './styles.css';
import HIMYM from './HIMYM.jpg';
import Haikyu from './haikyu.jpeg';
import naruto from './naruto.jpeg';
import volleyball from './VB.png';
import pingpong from './pingpong.jpg';
import GP from './GP.png';
import cypher from './cypher.jpg';
import lol from './Films & TV 2022-12-20 21-43-20.mp4'
const BoxContainer = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const boxData = [
        { src: HIMYM, src2: 'https://www.youtube.com/embed/TjapUF_5Brg?si=PAS5xAyFjr7IoF49', label: 'HIMYM', type: 'youtube' },
        { src: Haikyu, src2: 'https://www.youtube.com/embed/-hGs4HcpuwE?si=SntJLVdzGb0Skulz', label: 'Haikyu', type: 'youtube' },
        { src: naruto, src2: 'https://www.youtube.com/embed/0bjicmknkVw?si=EU49NG5uIdtmaFmP', label: 'Naruto', type: 'youtube' },
        { src: volleyball, src2: 'https://source.unsplash.com/1000x800', label: 'Volleyball', type: 'image' },
        { src: pingpong, src2: 'https://source.unsplash.com/1000x800', label: 'Ping Pong', type: 'image' },
        { src: GP, src2: lol, label: 'League of Legends', type: 'video' },
    ];

    return (
        <div className="container-600">
            {boxData.map((box, index) => (
                <div
                    key={index}
                    className={`box-600 ${hoveredIndex === index ? 'hovered' : ''}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    <div
                        className="box-overlay"
                        style={{ backgroundImage: `url(${box.src})` }}
                    />
                    {box.type === 'video' && hoveredIndex === index ? (
                        <video src={box.src2} alt={`Video ${index + 1}`} autoPlay controls />
                    ) : box.type === 'youtube' && hoveredIndex === index ? (
                        <iframe
                            width="100%"
                            height="100%"
                            src={box.src2}
                            title={`YouTube Video ${index + 1}`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    ) : (
                        <img src={hoveredIndex === index ? box.src2 : box.src} alt={`Image ${index + 1}`} />
                    )}
                    <span>{box.label}</span>
                </div>
            ))}
        </div>
    );
};

export default BoxContainer;
