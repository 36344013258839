import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaHardHat } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";
import { IoSchool } from "react-icons/io5";
import { LuFileSearch2 } from "react-icons/lu";

const MyTimeline = () => {
  return (
    <VerticalTimeline
    lineColor = "rgb(216, 191, 216)">

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(10, 10, 10)', color: '#fff', border: '2px solid rgb(30,30,30)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 30, 30)' }}
        date="Sep 2023 - present"
        iconStyle={{ background: 'rgb(10,10,10)', color: '#fff',border:'2px solid rgb(135, 206, 235)'}}
        icon={<LuFileSearch2 />}
      >
        <h3 className="vertical-timeline-element-title">Research Assistant</h3>
        <h4 className="vertical-timeline-element-subtitle">York University</h4>
        <p>
        • Analyze and optimize bioinformatics pipelines for 200 frog species using Python (pandas, NumPy) and Unix scripts to assemble and process target capture sequence data, aiming to improve data curation and analysis efficiency by 30% and contributing to research on frog visual evolution.
        </p>
        <p>
        • Develope and maintaine scripts for phylogenetic and molecular evolutionary analyses, assembling data for 150 new species using Python (BeautifulSoup) and R, supporting research on the visual evolution of frogs, salamanders, and turtles.
        </p>
        
      </VerticalTimelineElement>


      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(10, 10, 10)', color: '#fff', border: '2px solid rgb(30,30,30)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(30, 30, 30)' }}
        date="Sep 2023 - Sep 2024"
        iconStyle={{ background: 'rgb(10,10,10)', color: '#fff',border:'2px solid rgb(135, 206, 235)'}}
        icon={<FaComputer />}
      >
        <h3 className="vertical-timeline-element-title">Full-Stack Developer</h3>
        <h4 className="vertical-timeline-element-subtitle">The Regional Municipality of York</h4>
        <p>
        • Developed a Change Request Management app using Microsoft Power Apps, enabling users to submit tickets for
        application or configuration changes, speeding up communication, and reducing conflicts by 50%.
        </p>
        <p>
        • Collected and analyzed 20 system log files into a single interface by creating a Python and Flask app, centralizing log
        data and identifying specific errors, resulting in a 60% reduction in debugging time.
        </p>
        <p>
        • Developed Python and Selenium test scripts for multiple applications, boosting testing efficiency by 80% following
        developer changes, and ensuring that other interconnected applications remain unaffected by the updates.
        </p>
        <p>
        • Digitized various physical forms into IBM Maximo following a structured SDLC process, aligned with business
        upstream and downstream requirements.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(10, 10, 10)', color: '#fff', border: '2px solid rgb(30,30,30)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(20, 20, 20)' }}
        date="Jan 2023 – Jan 2024"
                iconStyle={{ background: 'rgb(10,10,10)', color: '#fff',border:'2px solid rgb(135, 206, 235)'}}

        icon={<FaComputer />}
      >
        <h3 className="vertical-timeline-element-title">Web Developer</h3>
        <h4 className="vertical-timeline-element-subtitle">Excel Lassonde</h4>
        <p>
        • the Excel Lassonde website, a pivotal service of the Lassonde School of Engineering designed to facilitate mentoring connections between upper-year and new students for academic support. Utilized JavaScript, HTML, and CSS to develop a robust and user-friendly platform.
        </p>
        <p>
        •  Provided and implemented a solution for students to book tutoring appointments through the website using
        Calendly, reducing 200 daily administrative tasks.
        </p>
        <p>
        • Actively collaborated with fellow team leaders and stakeholders to collect requirements and feedback, ensuring the website effectively met user needs and delivered a seamless experience.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(10, 10, 10)', color: '#fff', border: '2px solid rgb(30,30,30)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(20, 20, 20)' }}
        date="Sep 2021 - present"
                iconStyle={{ background: 'rgb(10,10,10)', color: '#fff',border:'2px solid rgb(135, 206, 235)'}}

        icon={<IoSchool />}
      >
        <h3 className="vertical-timeline-element-title">B.Sc., Hons. Computer Science</h3>
        <h4 className="vertical-timeline-element-subtitle">York University</h4>
        <p>
        • Maintained a high GPA throughout my academic tenure, earning the York University Continuing Student Scholarship in recognition of outstanding academic performance.
        </p>
        <p>
        • Organized and led group study sessions focused on complex mathematical problems, aiding peers in understanding and solving advanced math questions, thereby enhancing collective academic success.</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(10, 10, 10)', color: '#fff', border: '2px solid rgb(30,30,30)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(20, 20, 20)' }}
        date="Nov 2019 – present"
                iconStyle={{ background: 'rgb(10,10,10)', color: '#fff',border:'2px solid rgb(135, 206, 235)'}}

        icon={<FaHardHat  />}
      >
        <h3 className="vertical-timeline-element-title">Warehouse Associate</h3>
        <h4 className="vertical-timeline-element-subtitle">YYZ1 - Amazon Fulfillment Center</h4>
        <p>
        • Adept at working through departments, including Outbound department associate, and shipping dock associate.
        </p>
        <p>
        • Able to perform troubleshooting and testing on computers, programs, machines and other work equipment with awareness of safety procedures, and the use of dangerous equipment          
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

export default MyTimeline;
